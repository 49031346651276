

try {
  window.$ = window.jQuery = require('jquery');
  // window.Popper = require('@popperjs/core/dist/esm');
  window.Swal = require('sweetalert2/dist/sweetalert2');
  window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle');
  require('datatables.net-responsive-bs5');


  
} catch (exception) {
 // console.error(exception);
}


// Vanillajs Datepicker
import Datepicker from 'vanillajs-datepicker/Datepicker';


// Datepicker global verfügbar machen, wenn benötigt
window.Datepicker = Datepicker;

import dayjs from 'dayjs';
dayjs.locale('de'); 
window.dayjs = dayjs;




    